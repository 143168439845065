var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!!_vm.company)?_c('b-card',{staticClass:"mx-5 mt-2"},[_c('validation-observer',{ref:"companyRules",attrs:{"tag":"form"}},[_c('b-row',{staticClass:"ml-2"},[_c('b-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"d-flex justify-content-start"},[_c('b-avatar',{attrs:{"src":"" + _vm.publicPath + "images/customers/" + "" + (_vm.company.logo),"size":"130px","rounded":""}}),_c('div',{staticClass:"d-flex flex-column mt-2 ml-1"},[_c('div',{staticClass:"mb-1"},[_c('h4',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.company.name)+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.company.business_domain)+" ")]),_c('h5',{staticClass:"mt-1"},[_vm._v(" "+_vm._s(_vm.company.URL)+" ")])])])],1)])],1),_c('b-row',{staticClass:"mx-5"},[_c('b-col',{attrs:{"md":"6"}},[_c('h4',{staticClass:"mt-1"},[_vm._v("Détails")]),_c('b-form-group',{staticClass:"mt-2",attrs:{"label":"Nom","label-for":"nom"}},[_c('validation-provider',{attrs:{"name":"Nom","vid":"Nom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"placeholder":"Universal Exports"},model:{value:(_vm.company.name),callback:function ($$v) {_vm.$set(_vm.company, "name", $$v)},expression:"company.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2613431707)})],1),_c('b-form-group',{attrs:{"label":"Description","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"Description","vid":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first_name","state":errors.length > 0 ? false : null,"placeholder":"Description de l'entreprise"},model:{value:(_vm.company.description),callback:function ($$v) {_vm.$set(_vm.company, "description", $$v)},expression:"company.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2687804284)})],1),_c('b-form-group',{attrs:{"label":"Url","label-for":"url"}},[_c('validation-provider',{attrs:{"name":"Url","vid":"Url","rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"url","state":errors.length > 0 ? false : null,"placeholder":"https://mycompany.fr"},model:{value:(_vm.company.URL),callback:function ($$v) {_vm.$set(_vm.company, "URL", $$v)},expression:"company.URL"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3798841209)})],1),_c('b-form-group',{attrs:{"label":"Enregistrement","label-for":"enregistrement"}},[_c('validation-provider',{attrs:{"name":"Enregistrement","vid":"Enregistrement","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"registration_number","state":errors.length > 0 ? false : null,"placeholder":"654646G4545"},model:{value:(_vm.company.registration_number),callback:function ($$v) {_vm.$set(_vm.company, "registration_number", $$v)},expression:"company.registration_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,583823109)})],1),_c('b-form-group',{attrs:{"label":"Numéro TVA","label-for":"numéro TVA"}},[_c('validation-provider',{attrs:{"name":"Numéro TVA","vid":"Numéro TVA","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vat","state":errors.length > 0 ? false : null,"placeholder":"FR 56474747"},model:{value:(_vm.company.vat),callback:function ($$v) {_vm.$set(_vm.company, "vat", $$v)},expression:"company.vat"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2064396853)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('h4',{staticClass:"mt-1"},[_vm._v("Contact")]),_c('b-form-group',{staticClass:"mt-2",attrs:{"label":"Contact légal","label-for":"contact légal"}},[_c('validation-provider',{attrs:{"name":"Contact légal","vid":"Contact légal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"contact_legal","state":errors.length > 0 ? false : null,"placeholder":"Martin Dupont"},model:{value:(_vm.company.contact_legal),callback:function ($$v) {_vm.$set(_vm.company, "contact_legal", $$v)},expression:"company.contact_legal"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3227783000)})],1),_c('b-form-group',{attrs:{"label":"Contact email","label-for":"contact email"}},[_c('validation-provider',{attrs:{"name":"Contact email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"email","state":errors.length > 0 ? false : null,"placeholder":"martin.dupont@email.com"},model:{value:(_vm.company.email),callback:function ($$v) {_vm.$set(_vm.company, "email", $$v)},expression:"company.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3716122199)})],1),_c('b-form-group',{attrs:{"label":"Adresse 1","label-for":"adresse 1"}},[_c('validation-provider',{attrs:{"name":"Adresse 1","vid":"Adresse 1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address_1","state":errors.length > 0 ? false : null,"placeholder":"Bat 12,"},model:{value:(_vm.company.address_1),callback:function ($$v) {_vm.$set(_vm.company, "address_1", $$v)},expression:"company.address_1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,581512505)})],1),_c('b-form-group',{attrs:{"label":"Adresse 2","label-for":"adresse 2"}},[_c('validation-provider',{attrs:{"name":"Adresse 2","vid":"Adresse 2","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address_2","state":errors.length > 0 ? false : null,"placeholder":"Bat 12,"},model:{value:(_vm.company.address_2),callback:function ($$v) {_vm.$set(_vm.company, "address_2", $$v)},expression:"company.address_2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2237321177)})],1),_c('b-form-group',{attrs:{"label":"Code postal","label-for":"code postal"}},[_c('validation-provider',{attrs:{"name":"Code postal","vid":"Code postal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"zip_code","state":errors.length > 0 ? false : null,"placeholder":"69300"},model:{value:(_vm.company.zip_code),callback:function ($$v) {_vm.$set(_vm.company, "zip_code", $$v)},expression:"company.zip_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3138379837)})],1),_c('validation-provider',{attrs:{"name":"Pays","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Pays","label-for":"pays","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"country","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.countryName,"reduce":function (val) { return val.value; },"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.company.country),callback:function ($$v) {_vm.$set(_vm.company, "country", $$v)},expression:"company.country"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,4280149818)})],1)],1)],1),_c('b-row',{staticClass:"mt-2",attrs:{"align-h":"end"}},[_c('b-col',{attrs:{"md":"2"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"block":"","variant":"primary"},on:{"click":_vm.validationForm}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EditIcon"}}),_vm._v(" Editer ")],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }