























































































































































































































































import Vue from "vue";

import { ref } from "@vue/composition-api";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BCard,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { required, email, url } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default Vue.extend({
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BButton,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      url,
      publicPath: process.env.BASE_URL,
      countryName: [
        { value: "select_value", text: "Select Value" },
        { value: "France", text: "France" },
        { value: "Canada", text: "Canada" },
        { value: "China", text: "China" },
        { value: "United States", text: "United States" },
        { value: "Brazil", text: "Brazil" },
        { value: "Australia", text: "Australia" },
        { value: "India", text: "India" },
      ],
    };
  },
  setup() {
    const company = ref(null);

    store
      .dispatch("app-user/fetchCompanyByID", {
        companyID: store.state.auth.userProfile.company_ID,
      })
      .then((response) => {
        company.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          company.value = undefined;
        }
      });

    return {
      company,
    };
  },
  methods: {
    submitForm() {
      if (this.company.country.value !== undefined)
        this.company.country = this.company.country.value;
      this.$store
        .dispatch("app-user/editPartialCompany", {
          editPartialCompany: this.company,
        })
        .then(() => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Entreprise éditée",
                icon: "CheckIcon",
                variant: "success",
                text: "L'entreprise a été mise à jour.",
              },
            },
            { position: "top-center" }
          );
        })
        .catch((error) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Edition impossible",
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: error.message,
              },
            },
            { position: "top-center" }
          );
        });
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.companyRules.validate().then((success) => {
          if (success) {
            this.submitForm();
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
  },
});
