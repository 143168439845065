<template>
  <b-tabs class="mt-1">
    <b-tab active lazy>
      <template #title>
        <feather-icon icon="GlobeIcon" />
        <span>Entreprise</span>
      </template>

      <user-manage-company />
    </b-tab>
    <b-tab lazy>
      <template #title>
        <feather-icon icon="UsersIcon" />
        <span>Utilisateurs</span>
      </template>

      <user-manage-list />
    </b-tab>
  </b-tabs>
</template>

<script>
import { PageBoxed } from "@/views/utils/PageBoxed.js";
import { BTabs, BTab } from "bootstrap-vue";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import userStoreModule from "../userStoreModule";
import UserManageList from "./UserManageList.vue";
import UserManageCompany from "./UserManageCompany.vue";

export default {
  components: {
    BTabs,
    BTab,

    // VUE CUSTOM COMPONENTS
    UserManageList,
    UserManageCompany,
  },
  mixins: [PageBoxed],
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
  },
};
</script>
